import { HttpClient } from '@angular/common/http';
import { Component, HostListener, Input } from '@angular/core';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
function noSpacesValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  if (control.value && control.value.trim().length === 0) {
    return { noSpaces: true };
  }
  return null;
}
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent {
  name: any;
  email: any;
  phone_number: any;
  message: any;
  subject: any;
  contactForm: FormGroup = this.formBuilder.group({
    name: [
      '',
      [
        Validators.required,
        noSpacesValidator,
        Validators.minLength(2),
        Validators.maxLength(100),
      ],
    ],
    email: [
      '',
      [
        Validators.required,
        Validators.email,
        noSpacesValidator,
        Validators.minLength(2),
        Validators.maxLength(200),
      ],
    ],
    phone_number: [
      '',
      [
        Validators.required,
        Validators.pattern(/^\d{10}$/),
        Validators.maxLength(10),
      ],
    ],
    subject: [
      '',
      [
        Validators.required,
        noSpacesValidator,
        Validators.minLength(2),
        Validators.maxLength(200),
      ],
    ],
    message: [
      '',
      [
        Validators.required,
        noSpacesValidator,
        Validators.minLength(2),
        Validators.maxLength(200),
      ],
    ],
  });
  get companyName() {
    return this.contactForm.get('name');
  }
  get emailGet() {
    return this.contactForm.get('email');
  }
  get phoneNumber() {
    return this.contactForm.get('phone_number');
  }
  get subjectGet() {
    return this.contactForm.get('subject');
  }
  get messageGet() {
    return this.contactForm.get('message');
  }
  @Input() appMaxLength1: number = 10;

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue: string = input.value;

    // Check if the input has the 'appMaxLength1' attribute
    if (input.hasAttribute('appMaxLength1')) {
      const value = input.value;

      if (value.length > this.appMaxLength1) {
        // Update the FormControl value using patchValue
        this.contactForm
          .get('phone_number')
          ?.patchValue(value.slice(0, this.appMaxLength1));
      }
    }

    // Trim leading spaces
    if (inputValue && inputValue.startsWith(' ')) {
      input.value = inputValue.trimStart();
    }
  }

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private route: Router,
    private formBuilder: FormBuilder
  ) {}
  onKeyDown(event: KeyboardEvent): void {
    // Prevent the default behavior of the up and down arrow keys
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  }

  onKeyUp(event: KeyboardEvent): void {
    // Prevent the default behavior of the up and down arrow keys
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  }
  ngOnInit() {
    $('.alert-danger').hide();
    $('.alert-success').hide();
    setTimeout(() => {
      $('.overlay').hide();
    }, 500);
    window.scrollTo(0, 0);
    CommonService.ActiveClass('nav-contact');
  }

  onSendMessage() {
    // Display all validation errors
    Object.keys(this.contactForm.controls).forEach((field) => {
      const control = this.contactForm.get(field);
      if (control) {
        control.markAsTouched({ onlySelf: true });
      }
    });

    // Check if the form is valid
    if (this.contactForm.valid) {
      $('.overlay').show();

      // Your existing HTTP post request
      this.http
        .post(CommonService.apiUrl + '/contact-submit', {
          company_name: this.contactForm.value.name,
          email: this.contactForm.value.email,
          phone_number: this.contactForm.value.phone_number,
          message: this.contactForm.value.message,
          subject: this.contactForm.value.subject,
          oauth_key: CommonService.oauth_key,
        })
        .subscribe((response: any) => {
          if (response['is_successfull'] === '1') {
            $('.overlay').hide();
            $('.alert-success').show();
            $('.alert-success').html(response['success_message']);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            $('.overlay').hide();
          }
        });
    }

    // Prevent form submission
    return false;
  }

  validation() {
    $('.alert-danger').hide();
    let error_message = '';

    if (this.name === '' || this.name === undefined || this.name === null) {
      error_message = 'Please enter Name.</br>';
    }
    if (this.email === '' || this.email === undefined || this.email === null) {
      error_message += 'Please enter Email.</br>';
    } else {
      // Check for proper email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.email)) {
        error_message += 'Please enter a valid Email.</br>';
      }
    }
    if (
      this.phone_number === '' ||
      this.phone_number === undefined ||
      this.phone_number === null
    ) {
      error_message += 'Please enter Mobile Number.</br>';
    } else {
      // Check for proper phone number format
      const phoneRegex = /^\d{10}$/; // Adjust the regex based on your specific phone number format
      if (!phoneRegex.test(this.phone_number)) {
        error_message += 'Please enter a valid Mobile Number.</br>';
      }
    }
    if (
      this.message === '' ||
      this.message === undefined ||
      this.message === null
    ) {
      error_message += 'Please enter message.</br>';
    }
    if (
      this.subject === '' ||
      this.subject === undefined ||
      this.subject === null
    ) {
      error_message += 'Please enter subject.</br>';
    }

    if (error_message !== '') {
      $('.alert-success').hide();
      $('.alert-danger').show();
      $('.alert-danger').html(error_message);
      // Assuming you have a reference to the element with the id "validation_form"
      const validationForm = document.getElementById('validation_form');

      // Check if the element is found
      if (validationForm) {
        // Scroll to the top of the element
        validationForm.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      return false;
    } else {
      return true;
    }
  }
}
