import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
shownav:boolean=false;

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private route: Router
  ) {}
  ngOnInit(){

  }
  onHomeClick(){
    CommonService.ActiveClass('nav-home');

    this.route.navigateByUrl('/home');
    window.scrollTo(0,0);
  }
  onClickIndustry(){
    CommonService.ActiveClass('nav-industry');
    this.route.navigateByUrl('/testimonials');


  }
  onClickPricing(){
    CommonService.ActiveClass('nav-pricing');
    this.route.navigateByUrl('/pricing');

  }
  onClickPortfolio(){
    CommonService.ActiveClass('nav-portfolio');

  }
  onfeatureClick(){
    CommonService.ActiveClass('nav-feature');
    this.route.navigateByUrl('/features');


  }
  onservicesClick(){
    CommonService.ActiveClass('nav-services');
    this.route.navigateByUrl('/highlights');


  }
  onContactUs(){
    CommonService.ActiveClass('nav-contact');
    this.route.navigateByUrl('/contact-us');

  }
  onClickBlog(){
    CommonService.ActiveClass('nav-blog');
    this.route.navigateByUrl('/blogs');

  }
  onToogle(){
    this.shownav=!this.shownav;
    if(this.shownav===true){

      $('#navbarNav').addClass('show');
      $('#navbarNav').css('display', 'block');

    }
    else{
      $('#navbarNav').removeClass('show');
      $('#navbarNav').css('display', 'none');
    }
  }
}
