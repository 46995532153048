<!DOCTYPE html>
<html>
<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1">
	<title></title>

</head>
<body>

	<app-header></app-header>
    <div class="overlay">
		<i class="fa fa-spinner fa-spin" style="font-size: 80px"></i>
	  </div>

	<section class="hero-sec sec-padding">
		<div class="container">	
			<div class="row align-items-center">
				<div class="col-lg-12">
					<div>
						<h4 class="text-white text-center">Pricing of Field Service App</h4>
						<!-- <p class="text-center text-white mb-1">Real-Time GPS Tracking Of Employee.</p> -->
						<p class="text-center text-white"><a href="" class="text-white">Home</a> / Pricing of Field Service App</p>
					</div>
				</div>
			</div>
		</div>
	</section>


	

	<section class="features-sec sec-padding">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<h1 class="section-title-main text-center">Pricing</h1>
					<p class=" text-center">SmartOnSite is the easiest app and web based software that helps organisations to manage their remote human resources and assets efficiently online with access to authorized personals for their data of work and assets belonging to the organization. We have customized the pricing model to make it easy to adopt this world class process based application in your organization. If in case these models doesn’t suit you, we would be happy to assist to define even easier way to implement SmartOnSite with most suitable and affordable pricing model to your organization.</p>
				</div>
			</div>


      <section>
        <div class="container">
        <div class="pricing__table">
          <div class="pt__title">
            <div class="pt__title__wrap border-sec-main">
              <div class="pt__row">Features</div>
              <div class="pt__row">No of users</div>
              <div class="pt__row">Web Admin Dashaboard</div>
              <div class="pt__row">Technician Mobile App</div>
              <div class="pt__row">Complaints Management</div>
              <div class="pt__row">Trip Management</div>
              <div class="pt__row">Expense Management</div>
              <div class="pt__row">Inventory Management</div>
              <div class="pt__row">Attendance Management</div>
              <div class="pt__row">Scheduled Maintenance</div>
              <!-- <div class="pt__row">No of Users</div> -->
                <div class="pt__row">Client Branded Apps</div>
              <div class="pt__row">Custom Checklist</div>
              <div class="pt__row">Data sync with ERP</div>
              <div class="pt__row">Flow Customization</div>
             
              <!-- <div class="pt__row">Client Branded Apps</div> -->
              
            </div>
          </div>
          <div class="pt__option">
            <div class="pt__option__mobile__nav">
                <a id="navBtnLeft" class="mobile__nav__btn"  (click)="prevSlide()">
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.1538 11.9819H1.81972" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11.9863 22.1535L1.82043 11.9865L11.9863 1.81946" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </a>
                <a id="navBtnRight" class="mobile__nav__btn" (click)="nextSlide1()">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.81934 11.9819H22.1534" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11.9863 22.1535L22.1522 11.9865L11.9863 1.81946" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </a>
            </div>
            <div class="pt__option__slider swiper" id="pricingTableSlider">
              <div class="swiper-wrapper">
                <div class="swiper-slide pt__option__item">
                  <div class="pt__item ">
                    <div class="pt__item__wrap">
                      <div class="pt__row fisrt-color">Free Trial</div>
                      <div class="pt__row text-dark">5</div>
                      <div class="pt__row fisrt-color"><i class="fas fa-check"></i></div>
                      <div class="pt__row"><i class="fas fa-check"></i></div>
                      <div class="pt__row fisrt-color"><i class="fas fa-check"></i></div>
                      <div class="pt__row"><i class="fas fa-check"></i></div>
                      <div class="pt__row fisrt-color"><i class="fas fa-check"></i></div>
                      <div class="pt__row"><i class="fas fa-times"></i></div>
                      <div class="pt__row fisrt-color"><i class="fas fa-times"></i></div>
                      <div class="pt__row"><i class="fas fa-times"></i></div>
                      <!-- <div class="pt__row fisrt-color"><i class="fas fa-times"></i></div> -->
                      <div class="pt__row fisrt-color"><i class="fas fa-times"></i></div>
                      <div class="pt__row"><i class="fas fa-times"></i></div>
                      <div class="pt__row fisrt-color"><i class="fas fa-times"></i></div>
                      <div class="pt__row"><i class="fas fa-times"></i></div>
                     
                      <!-- <div class="pt__row"><i class="fas fa-times"></i></div> -->
                      <div class="pt__row">
                        <a>Free</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide pt__option__item">
                  <div class="pt__item ">
                    <div class="pt__item__wrap">
                      <div class="pt__row second-color">Standard</div>
                      <div class="pt__row text-dark">100</div>
                      <div class="pt__row second-color"><i class="fas fa-check"></i></div>
                      <div class="pt__row"><i class="fas fa-check"></i></div>
                      <div class="pt__row second-color"><i class="fas fa-check"></i></div>
                      <div class="pt__row"><i class="fas fa-check"></i></div>
                      <div class="pt__row second-color"><i class="fas fa-check"></i></div>
                      <div class="pt__row"><i class="fas fa-check"></i></div>
                      <div class="pt__row second-color"><i class="fas fa-check"></i></div>
                      <div class="pt__row"><i class="fas fa-check"></i></div>
                      <!-- <div class="pt__row second-color"><i class="fas fa-check"></i></div> -->
                      <div class="pt__row second-color"><i class="fas fa-times"></i></div>
                      <div class="pt__row"><i class="fas fa-times"></i></div>
                      <div class="pt__row second-color"><i class="fas fa-times"></i></div>
                      <div class="pt__row"><i class="fas fa-times"></i></div>
                      
                      <!-- <div class="pt__row"><i class="fas fa-times"></i></div> -->
                      <div class="pt__row">
                        <a >199/User/Month</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide pt__option__item">
                  <div class="pt__item">
                    <div class="pt__item__wrap">
                      <div class="pt__row third-color">Customized</div>
                      <div class="pt__row">Unlimited Users</div>
                      <div class="pt__row third-color"><i class="fas fa-check"></i></div>
                      <div class="pt__row"><i class="fas fa-check"></i></div>
                      <div class="pt__row third-color"><i class="fas fa-check"></i></div>
                      <div class="pt__row"><i class="fas fa-check"></i></div>
                      <div class="pt__row third-color"><i class="fas fa-check"></i></div>
                      <div class="pt__row"><i class="fas fa-check"></i></div>
                      <div class="pt__row third-color"><i class="fas fa-check"></i></div>
                      <div class="pt__row"><i class="fas fa-check"></i></div>
                      <div class="pt__row third-color"><i class="fas fa-check"></i></div>
                      <!-- <div class="pt__row third-color"><i class="fas fa-check"></i></div> -->
                      <div class="pt__row"><i class="fas fa-check"></i></div>
                      <div class="pt__row third-color"><i class="fas fa-check"></i></div>
                      <div class="pt__row"><i class="fas fa-check"></i></div>
                    
                      <!-- <div class="pt__row"><i class="fas fa-check"></i></div> -->
                      <div class="pt__row">
                        <a >On Demand</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
          </section>

		</div>
	</section>

	


	<section class="request-sec sec-padding">
		<div class="container">
			<div  class="row">
				<div class="col-md-9">
					<h2 class="text-white">Field Worker and Sales Staff Tracking with Smart On Site</h2>
				</div>
				<div class="col-md-3">
					<button class="btn request-btn" (click)="onHomePageScroll()">Request a Free Demo</button>
				</div>
			</div>
		</div>
	</section>
<app-slider></app-slider>

	<app-footer></app-footer>
</body>
</html>