import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.css']
})
export class FeatureComponent {
  logos:any[]=[];
  currentIndex: number = 0;
  displayedLogos: string[] = [];
  featureList: any[] = [];
  serviceList: any[] = [];
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private route: Router
  ) {}
  ngOnInit(){
  window.scrollTo(0,0);
  CommonService.ActiveClass('nav-feature');


  this.http
  .post(CommonService.apiUrl + '/get-all-features', {
    oauth_key:CommonService.oauth_key
  })
  .subscribe((response: any) => {
    if (response['is_successfull'] === '1') {
      this.featureList = response['data'];
      // let totalRecords = response['recordsTotal'];
      // let filterRecords = this.featureList.length;
      // let diff = totalRecords - filterRecords;
      // if (diff === 0 || diff < 0) {
      //   this.FeatureLoadMore = false;
      // }
      // else{
      //   this.FeatureLoadMore=true;
      // }

    }
  });
  this.http
  .post(CommonService.apiUrl + '/get-services', {
    oauth_key:CommonService.oauth_key
  })
  .subscribe((response: any) => {
    if (response['is_successfull'] === '1') {
      this.serviceList = response['data'];
    }
  });
  setTimeout(() => {
    $('.overlay').hide();
    }, 500);
}
onHomePageScroll(){
 localStorage.setItem("reqDemo","true");
  this.route.navigateByUrl('/home');
}

onFeatureReadMore(feature_id:any,featureTitle:any){
  const formattedTitle = featureTitle.replace(/\s+/g, '-').toLowerCase();

  // Navigate to the dynamic route
  localStorage.setItem('feature_id', feature_id);
    this.route.navigate(['/' + formattedTitle]);
}


}
