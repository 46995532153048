import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { IndustryListComponent } from './industry-list/industry-list.component';
import { BlogListComponent } from './blog-list/blog-list.component';
import { BlogIndividualComponent } from './blog-individual/blog-individual.component';
import { PricingComponent } from './pricing/pricing.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { FeatureComponent } from './feature/feature.component';
import { EllipsisPipe } from './ellipsis.pipe';
import { AlphabeticOnlyDirective } from './alphabetic-only.directive';
import { FeatureDetailsComponent } from './feature-details/feature-details.component';
import { SliderComponent } from './slider/slider.component';
const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'blogs', component: BlogListComponent },
  { path: 'testimonials', component: IndustryListComponent },
  { path: 'highlights', component: ServicesComponent },
  { path: 'features', component: FeatureComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'portfolio', component: PortfolioComponent },
  { path: 'contact-us', component: ContactComponent },
  { path: 'blog/:blogTitle', component: BlogIndividualComponent },

  { path: ':featureTitle', component: FeatureDetailsComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServicesComponent,
    IndustryListComponent,
    BlogListComponent,
    BlogIndividualComponent,
    PricingComponent,
    PortfolioComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    FeatureComponent,
    EllipsisPipe,
    AlphabeticOnlyDirective,
    FeatureDetailsComponent,
    SliderComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    RouterModule.forRoot(appRoutes),
  ],
  exports: [AlphabeticOnlyDirective],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
