<!DOCTYPE html>
<html>
<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width, initial-scale=1">
	<title></title>

</head>
<body>

	<app-header></app-header>

    <div class="overlay">
		<i class="fa fa-spinner fa-spin" style="font-size: 80px"></i>
	  </div>
	<section class="hero-sec sec-padding">
		<div class="container">	
			<div class="row align-items-center">
				<div class="col-lg-12">
					<div>
						<h4 class="text-white text-center">Testimonials of Field Service App </h4>
						<p class="text-center text-white"><a href="" class="text-white">Home</a> / Testimonials of Field Service App </p>
					</div>
				</div>
			</div>
		</div>
	</section>


	

	<section class="theme-light-bg sec-padding pt-5">
		<div class="container" *ngFor="let data of industryList; let i = index">
			<div class="row pb-5" *ngIf="i % 2 === 0">
				<div class="col-md-6">
					<div class="Industries-main-box" *ngFor="let image of data.images">
					<img [src]="image" class="text-center m-auto d-block pt90" width="50%">
					</div>
				</div>
				<div class="col-md-6 mt-2">
					<div class="comp-management">
						<h1 class="section-title">{{data.feedback_title}}</h1>
					<p class="" [innerHTML]="data.feedback_description"></p>
					</div>
				</div>
			</div>
			<div class="row pb-5"  *ngIf="i % 2 === 1">
				<div class="col-md-6 mt-2 order-lg-1 order-2">
					<div class="comp-management">
						<h1 class="section-title">{{data.feedback_title}}</h1>
					<p class="" [innerHTML]="data.feedback_description"></p>
					</div>
				</div>
				<div class="col-md-6  order-lg-2 order-1 text-center">
					<div class="Industries-main-box" *ngFor="let image of data.images">
					<img [src]="image" class="text-center m-auto d-block pt90" width="50%">
					</div>
				</div>
			</div>
		</div>		
	</section>


	


	<section class="request-sec sec-padding">
		<div class="container">
			<div  class="row">
				<div class="col-md-9">
					<h2 class="text-white">Transform Your On-Site Projects with Smart Management Tools</h2>
				</div>
				<div class="col-md-3">
					<button class="btn request-btn" (click)="onHomePageScroll()">Request a Free Demo</button>
				</div>
			</div>
		</div>
	</section>
<app-slider></app-slider>

	<app-footer></app-footer>
</body>
</html>