import { HttpClient } from '@angular/common/http';
import { Component, HostListener } from '@angular/core';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import { AfterViewInit } from '@angular/core';
import Swiper from 'swiper';


@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent {


  logos: any[] = [];
  currentIndex: number = 0;
  displayedLogos: string[] = [];
  pricingCardSwiper: Swiper | undefined;
  init = false;

  // Initialize Swiper with navigation buttons
  swiperCard() {
    if (window.innerWidth <= 991) {
      if (!this.init) {
        this.init = true;
        this.pricingCardSwiper = new Swiper('#pricingTableSlider', {
          slidesPerView: 'auto',
          spaceBetween: 5,
          grabCursor: true,
          navigation: {
            nextEl: '#navBtnRight', // Assign next button
            prevEl: '#navBtnLeft',  // Assign prev button
          },
        });
      }
    } else if (this.init) {
      this.pricingCardSwiper?.destroy();
      this.init = false;
    }
  }

  // Hook to initialize swiper after the view has been loaded
  ngAfterViewInit() {
    this.swiperCard();
  }

  // Re-initialize or destroy swiper on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.swiperCard();
  }
  nextSlide1() {
    this.pricingCardSwiper?.slideNext();
  }
  
  prevSlide() {
    this.pricingCardSwiper?.slidePrev();
  }
  
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private route: Router
  ) {}
  ngOnInit() {
    window.scrollTo(0, 0);
    CommonService.ActiveClass('nav-pricing');
    $('.overlay').hide();


  }
  onHomePageScroll(){
    localStorage.setItem('reqDemo','true');
    this.route.navigateByUrl('/home');
  }

}
