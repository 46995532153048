<section class="brand sec-padding">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="section-title-main text-center">Trusted by 300+ Companies</h1>
      </div>
      <div class="col-lg-12">
        <div class="testimonial-slider-wrapper">
          <div class="testimonial-slider" [ngStyle]="{ transform: getTransform() }">
            <div *ngFor="let logo of logos" class="image_div">
              <img [src]="logo" alt="Company Logo" class="image_class" />
            </div>
            <div *ngFor="let logo of logos" class="image_div">
              <img [src]="logo" alt="Company Logo" class="image_class" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
