import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
// export class SliderComponent {
//   logos: string[] = [];
//   currentIndex: number = 0;
//   autoSlideInterval: any;

//   constructor(private http: HttpClient) {}

//   // ngOnInit() {
//   //   this.http
//   //     .post(CommonService.apiUrl + '/get-images', {
//   //       oauth_key: CommonService.oauth_key,
//   //     })
//   //     .subscribe((response: any) => {
//   //       if (response['is_successfull'] === '1') {
//   //         for (let i = 0; i < response['data'].length; i++) {
//   //           this.logos.push(response['data'][i]['image']);
//   //         }
//   //         this.startAutoSlide(); // Start the automatic slide on init
//   //       }
//   //     });
//   // }
//   ngOnInit() {
//     this.http
//       .post(CommonService.apiUrl + '/get-images', {
//         oauth_key: CommonService.oauth_key,
//       })
//       .subscribe((response: any) => {
//         if (response['is_successfull'] === '1') {
//           for (let i = 0; i < response['data'].length; i++) {
//             this.logos.push(response['data'][i]['image']);
//           }
//           // Duplicate logos for seamless scrolling
//           this.logos = [...this.logos, ...this.logos]; // Concatenate the array with itself
//         }
//       });
//   }
//   ngOnDestroy() {
//     clearInterval(this.autoSlideInterval); // Clean up interval on component destroy
//   }

//   getTransform(): string {
//     // Translate X by the current index, showing one logo at a time
//     return `translateX(${-this.currentIndex * 0.0001}%)`;
//   }

//   nextSlide() {
//     // Move to the next slide, wrap around if at the end of the array
//     this.currentIndex = (this.currentIndex + 1) % this.logos.length;
//   }

//   startAutoSlide() {
//     // Automatically slide to the next logo every 3 seconds
//     this.autoSlideInterval = setInterval(() => {
//       this.nextSlide();
//     }); // Adjust the interval time (3000 ms = 3 seconds)
//   }
//   prevSlide() {
//     // Move to the previous slide, wrap around if at the first slide
//     this.currentIndex = (this.currentIndex - 1 + this.logos.length) % this.logos.length;
//   }
  
// }
// export class SliderComponent {
//   logos: string[] = [];

//   constructor(private http: HttpClient) {}

//   ngOnInit() {
//     this.http
//       .post(CommonService.apiUrl + '/get-images', {
//         oauth_key: CommonService.oauth_key,
//       })
//       .subscribe((response: any) => {
//         if (response['is_successfull'] === '1') {
//           for (let i = 0; i < response['data'].length; i++) {
//             this.logos.push(response['data'][i]['image']);
//           }
//         }
//       });
//   }
// }
export class SliderComponent implements OnInit, OnDestroy {
  logos: string[] = [];
  currentIndex: number = 0;
  autoSlideInterval: any;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.http
      .post(CommonService.apiUrl + '/get-images', {
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          this.logos = response['data'].map((item:any) => item['image']);
          this.startAutoSlide(); // Start sliding after logos are loaded
        }
      });
  }

  ngOnDestroy() {
    clearInterval(this.autoSlideInterval); // Clean up the interval
  }

  getTransform(): string {
    // Calculate the transform based on the current index and logo width
    const logoWidth = 200; // Adjust this according to your actual logo width
    return `translateX(${-this.currentIndex * logoWidth}px)`;
  }

  nextSlide() {
    // Move to the next slide
    this.currentIndex++;
    if (this.currentIndex >= this.logos.length) {
      this.currentIndex = 0; // Reset to the first logo
    }
  }

  startAutoSlide() {
    // Automatically slide to the next logo every 3 seconds
    this.autoSlideInterval = setInterval(() => {
      this.nextSlide();
      // Update the transform style after moving to the next slide
      document.querySelector('.testimonial-slider')!.setAttribute('style', `transform: ${this.getTransform()}`);
    }, 3000); // Adjust time (3000 ms = 3 seconds)
  }
}
