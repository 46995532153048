import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { CommonService } from '../common.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-blog-individual',
  templateUrl: './blog-individual.component.html',
  styleUrls: ['./blog-individual.component.css']
})
export class BlogIndividualComponent {
  logos:any[]=[];
  currentIndex: number = 0;
  displayedLogos: string[] = [];
  blogList: any[] = [];
  blog: any[] = [];
  blog_id:any;
  blogTitle:any;
  blog_title:any
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private route: Router,
    private router: ActivatedRoute,

  ) {}
  ngOnInit(){
  window.scrollTo(0,0);
  
  CommonService.ActiveClass('nav-blog');
  this.blogTitle = this.router.snapshot.paramMap.get('blogTitle');

  this.blogAPI(this.blogTitle);
  this.http
  .post(CommonService.apiUrl + '/get-blog', {
    oauth_key:CommonService.oauth_key
  })
  .subscribe((response: any) => {
    if (response['is_successfull'] === '1') {
      this.blogList = response['data'];

     
    }
  });
  setTimeout(() => {
    $('.overlay').hide();
  }, 1000);
}
blogAPI(title:any){
  this.http
  .post(CommonService.apiUrl + '/blog-edit', {
    blog_title:this.blogTitle,
    oauth_key:CommonService.oauth_key
  })
  .subscribe((response: any) => {
    if (response['is_successfull'] === '1') {
      this.blog = response['data'];
      this.blog_title=this.blog[0].blog_title;
      $('.overlay').hide();
      // let totalRecords = response['recordsTotal'];
      // let filterRecords = this.featureList.length;
      // let diff = totalRecords - filterRecords;
      // if (diff === 0 || diff < 0) {
      //   this.FeatureLoadMore = false;
      // }
      // else{
      //   this.FeatureLoadMore=true;
      // }

    }
  });
}
onHomePageScroll(){
  localStorage.setItem('reqDemo','true');
  this.route.navigateByUrl('/home');
}



onClickBlog(blogTitle:any){
  $('.overlay').show();
  const formattedTitle = blogTitle.replace(/\s+/g, '-').toLowerCase();
  this.blogTitle=formattedTitle;

  // Navigate to the dynamic route
  // localStorage.setItem('blog_id',blog_id);
  this.blogAPI(this.blogTitle);
    this.route.navigate(['/blog/' + formattedTitle]);
}
BlogList(){
  this.route.navigateByUrl('/blogs');
}
}
