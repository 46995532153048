<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title></title>
  </head>
  <body>
    <app-header></app-header>
    <div class="overlay">
      <i class="fa fa-spinner fa-spin" style="font-size: 80px"></i>
    </div>
    <section class="hero-sec sec-padding">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12">
            <div>
              <h4 class="text-white text-center">Blogs</h4>
              <!-- <p class="text-center text-white mb-1">Real-Time GPS Tracking Of Employee.</p> -->
              <p class="text-center text-white">
                <a href="" class="text-white" style="text-decoration: underline;">Home</a> / Blogs
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="theme-light-bg sec-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <div class="category-list">
                  <h3>Recent blogs</h3>

                  <div
                    class="category-list-div mt-4 d-block"
                    *ngFor="let data of blogList" 
                  >
                    <a (click)="onClickBlog(data.blog_id,data.blog_title)"
                      ><i class="fas fa-angle-right"></i
                      >{{ data.blog_title }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-9 mt-2">
            <div class="row">
              <div class="col-md-6 blog_list"  *ngFor="let data of blogList">
                <div class="card rounded blog-card"  (click)="onClickBlog(data.blog_id,data.blog_title)">
                  <div class="card-body">
                    <div class="blog-img images_blog" *ngFor="let image of data.images">
                      <img
                        [src]="image"
                        class="text-center m-auto d-block"
                        width="100%"
                      />
                    </div>
                    <div class="blog-detail mt-4">
                      <h2 class="">{{data.blog_title}}</h2>
                      <p [innerHTML]="data.blog_description_sort| ellipsis : 100">
                       
                      </p>
                      <div style="text-align: left;">   <button class="btn mt-4 default-btn" (click)="onClickBlog(data.blog_id,data.blog_title)">
                        Read More
                       </button></div>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="request-sec sec-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-9">
            <h2 class="text-white">
              Field Worker and Sales Staff Tracking with Smart On Site
            </h2>
          </div>
          <div class="col-md-3">
            <button class="btn request-btn" (click)="onHomePageScroll()">Request a Free Demo</button>
          </div>
        </div>
      </div>
    </section>
  <app-slider></app-slider>

    <app-footer></app-footer>
  </body>
</html>
