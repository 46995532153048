<!DOCTYPE html>
<html>

<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <title></title>
</head>

<body>
  <app-header></app-header>
  <div class="overlay">
    <i class="fa fa-spinner fa-spin" style="font-size: 80px"></i>
  </div>
  <section class="hero-sec sec-padding">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="slider-img">
            <img src="../../assets/img/feature_img.png" class="w-100 m-auto d-block" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="main-heading text-white">
            <h4>Field Worker and Sales Staff Tracking with SmartOnsite</h4>
            <p>
              Field worker productivity tracking along with GPS route tracking
              is made easy with our intelligent SmartOnSite Mobile App and Web
              based software for managers.
            </p>
          </div>
          <div class="text-main-new text-white align-items-center mb-3">
            <h5 class="mb-3">1. Complaint Management</h5>
            <h5 class="mb-3">2. Expense Management</h5>
            <h5 class="mb-3">3. Spare parts inventory</h5>
            <h5 class="mb-3">4. Trip tracking</h5>
            <h5 class="mb-3">5. Analytical Reports</h5>
          </div>
          <div class="email-main mt-5">
            <div class="">
              <button type="button" class="btn top-slider-btn-main" data-bs-toggle="modal"
                data-bs-target="#staticBackdrop">
                Request For Demo
              </button>
             

              <!-- Button to Open Modal -->
              <button type="button" class="btn top-slider-btn-main1" (click)="openModal()">
                View App Video
            </button>
            
            <!-- Modal -->
            <div class="modal fade" id="staticBackdrop1" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                 
                  <div class="modal-body">
                    <!-- Embed YouTube Video -->
                    <div class="ratio ratio-16x9">
                      <iframe width="100%" height="315" src="https://www.youtube.com/embed/-865n28mi8o?si=ySdOJxILdaOmMI6A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" 
                      allowfullscreen></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>





              <!-- Modal -->
              <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title fw-bold" id="staticBackdropLabel" data-bs-target="#staticBackdrop">{{heading_modal}}</h5>
                      <button type="button" class="btn-close font-black" data-bs-dismiss="modal"
                        aria-label="Close"  (click)="clearForm()"></button>
                    </div>
                    <div class="modal-body">
                      <div class="col-md-12">
                        <div class="slider-form p-0">
                          <div class="alert alert-success" role="alert"></div>
                          <div class="alert alert-danger" role="alert"></div>
                          <!-- your-component.component.html -->
                          <form #form="ngForm" (ngSubmit)="submit(form)" class="form">
                            <!-- Company Name -->
                            <div class="form-group">
                              <label class="fw-bold">Company Name<span class="text-danger">*</span></label>
                              <input type="text" class="form-control" [(ngModel)]="company_name" [ngModelOptions]="{ standalone: true }" (ngModelChange)="validateCompanyName()" placeholder="Company Name"
                                maxlength="100" minlength="2" />
                              <div *ngIf="company_flag" class="error_code">
                                {{ company_error }}
                              </div>
                            </div>
                          
                            <!-- Email -->
                            <div class="form-group">
                              <label class="fw-bold">Email<span class="text-danger">*</span></label>
                              <input type="text" class="form-control" [(ngModel)]="email_address" [ngModelOptions]="{ standalone: true }" (ngModelChange)="validateEmail()"
                                maxlength="100" placeholder="Email"/>
                              <div *ngIf="email_flag" class="error_code">
                                {{ email_error }}
                              </div>
                            </div>
                          
                            <!-- Mobile -->
                            <div class="form-group row">
                              <div class="col-md-12">
                                <label class="fw-bold">Mobile<span class="text-danger">*</span></label>
                              </div>
                              <div class="col-md-4 col-4">
                                <select class="form-control code_button" [(ngModel)]="country_code_id" [ngModelOptions]="{ standalone: true }">
                                  <option *ngFor="let data of country_code_list" [value]="data.country_code_id">
                                    {{ data.country_code }}
                                  </option>
                                </select>
                              </div>
                              <div class="col-md-8 col-8">
                                <input 
                                  type="text" 
                                  class="form-control"
                                  [(ngModel)]="phone_number"
                                  [ngModelOptions]="{ standalone: true }"
                                  (input)="validatePhoneNumber($event)" 
                                  maxlength="10" 
                                  placeholder="Mobile Number"
                                />
                                <div *ngIf="phone_flag" class="error_code">
                                  {{ phone_error }}
                                </div>
                              </div>
                            </div>
                          
                            <!-- Address -->
                            <div class="form-group">
                              <label class="fw-bold">Address<span class="text-danger">*</span></label>
                              <input type="text" class="form-control" [(ngModel)]="company_address" [ngModelOptions]="{ standalone: true }" placeholder="Address" (ngModelChange)="validateAddress()"
                                maxlength="300" minlength="2" />
                              <div *ngIf="address_flag" class="error_code">
                                {{ address_error }}
                              </div>
                            </div>
                          
                            <button type="submit" class="btn default-btn">Submit</button>
                          </form>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

<app-slider></app-slider>


  <section class="sec-padding">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="section-title-main text-center">
            Highlights of SmartOnSite
          </h1>

        </div>
      </div>
      <div class="" *ngFor="let data of servicesList; let i = index">
        <div class="row align-items-center " *ngIf="i % 2 === 0">
          <div class="col-md-6">
            <div class="m-auto text-center" *ngFor="let image of data.images">
              <img class="img-comp" [src]="image" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="comp-desc">
              <h1 class="section-title">{{ data.service_title }}</h1>
              <p [innerHTML]="data.service_description"></p>
            </div>

          </div>
        </div>
      
        <div class="row align-items-center" *ngIf="i % 2 === 1">
          <div class="col-md-6 order-lg-1 order-2">
            <div class="comp-desc">
              <h1 class="section-title">{{ data.service_title }}</h1>
              <p [innerHTML]="data.service_description"></p>
            </div>

          </div>
          <div class="col-md-6 order-lg-2 order-1">
            <div class="m-auto text-center" *ngFor="let image of data.images">
              <img class="img-comp" [src]="image" />
            </div>
          </div>
        </div>
      </div>
      
      <!-- Sentinel Element for Infinite Scroll Trigger -->
      <div  #infiniteScrollTrigger id="infinite-scroll-trigger"></div>
      
    </div>
  </section>

  <section class="request-sec sec-padding">
    <div class="container">
      <div class="row">
        <div class="col-md-9">
          <h2 class="text-white">
            Transform Your On-Site Projects with Smart Management Tools
          </h2>
        </div>
        <div class="col-md-3">
          <!-- <button class="btn request-btn" (click)="onHomePageScroll()">
              Request a Free Demo
            </button> -->
          <button class="btn request-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            Request a Free Demo
          </button>
        </div>
      </div>
    </div>
  </section>

  <section class="features-sec sec-padding">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="section-title-main text-center">
            SmartOnSite is an Efficiant App
          </h1>
          <p class="text-center">
            The app is designed to help organizations having their post sales workflow at remote locations. This app will take care of entire lifecycle of Installation planning, resource deployment, expense tracking, inventory tracking, service schedule management and travel expenses done at remote locations. It also helps sales people to claim their expense against their work and travel done during their off site visits.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4" *ngFor="let data of featureList">
          <div class="features-div text-center">
            <img [src]="data.images" class="fix_image" (click)="onFeatureReadMore( data.feature_id, data.feature_title)"/>
            <h1 class="section-title" (click)="onFeatureReadMore( data.feature_id, data.feature_title)">{{ data.feature_title }}</h1>
            <p class="other-dis" [innerHTML]="data.feature_description_sort | ellipsis: 135"></p>
            <div>
              <a class="text-default text-decoration-none" (click)="onFeatureReadMore( data.feature_id, data.feature_title)">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Lazy load anchor -->
      <div id="load-more-anchor" #loadMoreAnchor></div>
      
    </div>
  </section>

  <section class="theme-light-bg sec-padding">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="section-title-main text-center">Client’s Feedback</h1>
          <p class="text-center">
            Smart Onsite helps thousands of companies worldwide in daily work
          </p>
        </div>
        <div class="col-md-12">
          <main>
            <section>
              <div class="testimonial">
                <div class="container">
                  <div class="testimonial__inner">
                    <div class="testimonial-slider" [style.--total-slides]="totalSlides"
                      [style.animation-duration.s]="animationDuration + 's'">
                      <div class="testimonial-slide" *ngFor="let data of feedBackList" class="col-md-6">
                        <div class="testimonial_box">
                          <div class="testimonial_box-inner">
                            <div class="testimonial_box-top">
                              <div class="testimonial_box-icon">
                                <i class="fas fa-quote-right"></i>
                              </div>
                              <div class="testimonial_box-text">
                                <p [innerHTML]="data.feedback_description | ellipsis : 100"></p>
                                <div>
                                  <a class="text-default text-decoration-none" (click)="onClickTestinomial()">
                                    Read More
                                  </a>
                                </div>
                              </div>
                              
                              <div class="testimonial_box-img" *ngFor="
                                    let image of data.images;
                                    let i = index
                                  ">
                                <img [src]="image" alt="profile" />
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>
  </section>

  <section class="sec-padding">
    <div class="container" (click)="onClickBlog()">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12" *ngFor="let data of blogList">
          <div class="card-container">
            <div class="card-image" *ngFor="let image of data.images">
              <img [src]="image" alt="a brand new sports car" />
            </div>
            <div class="card-body">
              <span class="card-badge card-badge-blue">{{
                data.blog_title
                }}</span>
              <p class="card-subtitle" [innerHTML]="data.blog_description_sort | ellipsis : 100"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>
</body>

</html>