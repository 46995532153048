import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  // public static apiUrl = 'http://localhost:3000/api/front';
  public static apiUrl = environment.apiUrl;
  public static oauth_key = 'wYIXdiZIJ7OLPA384f19lWdh2jALNHTME4WR0Z79UPLB40PH0abVUHFT8';
 
  private ctrlPressed = false;
  constructor(private http:HttpClient) {

    document.addEventListener('keydown', (event) => {
      if (event.keyCode === 17) {
        this.ctrlPressed = true;
      }

      if (event.keyCode === 116 && this.ctrlPressed) {
        // Ctrl + F5
        this.reloadPage();
        this.ctrlPressed = false;
      } else if (event.keyCode === 116 && !this.ctrlPressed) {
        // F5
        this.reloadPage();
      }
    });
   }
  // getData(): Observable<any[]> {
  //   return this.http.get<any[]>(this.apiUrl);
  // }
  public static ActiveClass(navClass:any){
    $('.navbar-nav li').removeClass('active');
    
    // Add activeClass to the selected li element
    $('.'+navClass).addClass('active');
  }
  private reloadPage() {
    location.reload();
  }
}
