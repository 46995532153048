<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title></title>
    <!-- <link href="css/bootstrap.min.css" rel="stylesheet">
	<link href="css/style.css" rel="stylesheet">
	<link href="css/icons.min.css" rel="stylesheet"> -->
  </head>
  <body>
    <app-header></app-header>
    <div class="overlay">
      <i class="fa fa-spinner fa-spin" style="font-size: 80px"></i>
    </div>
    <section class="hero-sec sec-padding">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12">
            <div>
              <h4 class="text-white text-center">Functionalities of Field Service App Highlights </h4>
              <!-- <p class="text-center text-white mb-1">Real-Time GPS Tracking Of Employee.</p> -->
              <p class="text-center text-white">
                <a href="" class="text-white">Home</a> / Functionalities of Field Service App Highlights 
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="theme-light-bg padding-contain pt-5">
      <div class="container" *ngFor="let data of serviceList; let i = index">
        <div class="row  pb-5" *ngIf="i % 2 === 0">
          <div class="col-md-5 text-center"  *ngFor="let image of data.images">
            <img
              [src]="image"
              class="text-center m-auto d-block image-width"
              
            />
          </div>
          <div class="col-md-7 mt-2">
            <div class="comp-management">
              <h1 class="section-title">{{data.service_title}}</h1>
              <p  [innerHTML]="data.service_description">
               
              </p>
             

        
            </div>
          </div>
        </div>
        <div class="row pb-5  " *ngIf="i % 2 === 1">
          <div class="col-md-7 mt-2 order-lg-1 order-2" >
            <div class="comp-management">
              <h1 class="section-title">{{data.service_title}}</h1>
              <p  [innerHTML]="data.service_description"></p>
        
            </div>
          </div>
          <div class="col-md-5 order-lg-2 order-1 text-center" *ngFor="let image of data.images">
            <img
              [src]="image"
              class="text-center m-auto d-block image-width"
              
            />
          </div>
        </div>
      </div>
    </section>
    <section class="theme-light-bg">
      <div class="container">
        <div>
          <h4 class="text-black text-center">Features of Field Service App </h4>
        </div>
      <div class="row">
        <div class="col-lg-4" *ngFor="let data of featureList">
          <div class="features-div text-center">
            <img class="fix_image" [src]="data.images" [alt]="data.feature_title"(click)="onFeatureReadMore(data.feature_id,data.feature_title)"/>
            <h1 class="section-title" (click)="onFeatureReadMore(data.feature_id,data.feature_title)">{{ data.feature_title }}</h1>
            <p  class="other-dis" [innerHTML]="data.feature_description_sort"></p>
            <a class="text-default text-decoration-none" (click)="onFeatureReadMore(data.feature_id,data.feature_title)">
              Read More
            </a>
          </div>
        </div>
        </div>
      </div>
    </section>
    <section class="request-sec sec-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-9">
            <h2 class="text-white">
              Transform Your On-Site Projects with Smart Management Tools
            </h2>
          </div>
          <div class="col-md-3">
            <button class="btn request-btn" (click)="onHomePageScroll()">Request a Free Demo</button>
          </div>
        </div>
      </div>
    </section>
 <app-slider></app-slider>

  <app-footer></app-footer>

    <!-- <script type="text/javascript" src="js/jquery-3.6.0.min.js"></script> 
	<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" ></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js" ></script>
	<script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.js"></script>
	<script type="text/javascript" src="js/main.js"></script> -->
  </body>
</html>
