import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-feature-details',
  templateUrl: './feature-details.component.html',
  styleUrls: ['./feature-details.component.css'],
})
export class FeatureDetailsComponent implements OnInit {
  featureDetails: any = null;
  feature_id: any;
  featureTitle: any;
  featureList: any[] = [];
  filteredFeatureList: any[] = [];
  logos: string[] = [];
  currentIndex: number = 0;
  displayedLogos: string[] = [];
  feature_title: string = '';

  constructor(
    private http: HttpClient,
    private route: Router,
    private router: ActivatedRoute,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    // Scroll to the top of the page on load
    window.scrollTo(0, 0);
    this.featureTitle = this.router.snapshot.paramMap.get('featureTitle');
    // Add active class to navigation or any specific element
    CommonService.ActiveClass('nav-feature');

    // Get the feature ID from localStorage
    // let storedFeatureId: any = localStorage.getItem('feature_id');

    // if (storedFeatureId) {
    //   this.feature_id = parseInt(storedFeatureId);
    this.getFeatureDetails(this.featureTitle);
    // } else {
    //   // If no featureId is found, navigate back or handle error
    //   this.route.navigateByUrl('/features');
    // }
    this.http
      .post(CommonService.apiUrl + '/get-all-features', {
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          this.featureList = response['data'];
        }
      });
    // Fetch logos (images)

    this.filterFeatures();
    setTimeout(() => {
      $('.overlay').hide();
    }, 500);
  }
  onFeatureReadMore(feature_id: any, featureTitle: any) {
    $('.overlay').show();
    const formattedTitle = featureTitle.replace(/\s+/g, '-').toLowerCase();
    this.getFeatureDetails(formattedTitle);
    // Navigate to the dynamic route
    // localStorage.setItem('feature_id', feature_id);
    // this.ngOnInit();
    this.route.navigate(['/' + formattedTitle]);
  }

  filterFeatures() {
    // Filter featureList to exclude features that are already in featureDetails
    this.filteredFeatureList = this.featureList.filter(
      (feature) =>
        !this.featureDetails.some(
          (detail: any) => detail.feature_id === feature.feature_id
        )
    );
  }
  getFeatureDetails(feature_title: any) {
    this.http
      .post(CommonService.apiUrl + '/get-feature-details', {
        feature_title: feature_title, // Change 'feature_id' to 'id'
        oauth_key: CommonService.oauth_key,
      })
      .subscribe(
        (response: any) => {
          if (response.is_successfull === '1') {
            this.featureDetails = response.data;
            if (this.featureDetails.length != 0) {
              this.feature_title = this.featureDetails[0].feature_title;
              this.feature_id = this.featureDetails[0].feature_id;
              window.scrollTo(0, 0);
            } else {
              $('.overlay').hide();
              this.route.navigateByUrl('/home');
            }
            setTimeout(() => {
              $('.overlay').hide();
            }, 1000);
          } else {
            $('.overlay').hide();
            console.error(
              'Error fetching feature details',
              response.error_message
            );
          }
        },
        (error) => {
          console.error('API Error', error);
        }
      );
  }

  // Fetch the feature details based on featureId
  onHomePageScroll() {
    localStorage.setItem('reqDemo', 'true');
    this.route.navigateByUrl('/home');
  }
  // Example methods for showing images
}
