<!DOCTYPE html>
<html>

<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <title></title>
  <!-- <link href="css/bootstrap.min.css" rel="stylesheet">
	<link href="css/style.css" rel="stylesheet">
	<link href="css/icons.min.css" rel="stylesheet"> -->
</head>

<body>
  <div class="content">
  <app-header></app-header>
  <div class="overlay">
    <i class="fa fa-spinner fa-spin" style="font-size: 80px"></i>
  </div>
  <section class="hero-sec sec-padding">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <div>
            <h4 class="text-white text-center">Contact Us</h4>
            <!-- <p class="text-center text-white mb-1">Real-Time GPS Tracking Of Employee.</p> -->
            <p class="text-center text-white">
              <a href="" class="text-white">Home</a> / Contact Us
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="sec-padding contact-us-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="card">
            <div class="d-flex">
              <div class="icon-div">
                <i class="far fa-envelope"></i>
              </div>
              <div class="main-ti-div ml-3">
                <h4 class="mb-1">Email Address</h4>
                <p>Mail us at</p>
              </div>
            </div>
            <div class="con-detail-text-div">

              <!-- <p class="mb-0">leads@nichetech.in</p> -->
              <a href="https://mail.google.com/mail/u/0/?fs=1&to=leads@nichetech.in&su=&body=&bcc=&tf=cm"
                class="address_link" target="_blank">
                <p class="mb-0">leads&#64;nichetech.in</p>
              </a>
              <!-- <a
                  href="http://www.nichetech.in"
                  class="address_link"
                  target="_blank"
                  ><p class="mb-0">www.nichetech.in</p></a
                > -->
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="d-flex">
              <div class="icon-div">
                <i class="fa-brands fa-whatsapp" style="margin-left: 17px;"></i>
              </div>
              <div class="main-ti-div ml-3">
                <a href="https://api.whatsapp.com/send?phone=+919512180005&text=Hello%20SmartOnSite" target="_blank" style="text-decoration: none;color: black;">
                <h4 class="mb-1">Whatsapp Number</h4>
                <p>Contact Us now on Whatsapp</p>
              </a>
              </div>
            </div>
            <div class="con-detail-text-div">
              <a href="https://api.whatsapp.com/send?phone=+919512180005&text=Hello%20SmartOnSite" target="_blank" style="text-decoration: none;">
                  <p class="mb-0">+91 95121 80005
                  </a>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="d-flex">
              <div class="icon-div">
                <i class="fa fa-address-card" aria-hidden="true"></i>

              </div>
              <div class="main-ti-div ml-3">
                <h4 class="mb-1">Office Address</h4>
                <p>Visit Our Office</p>
              </div>
            </div>
            <div class="con-detail-text-div">
              <a class="address_link" target="_blank"
                href="https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUMxaHRhNEhBEAE!2m1!1s0x0:0x8cd1bf81df9a0903!3m1!1s2@1:CIHM0ogKEICAgIC1hta4HA%7CCgwIzJe2rAYQwNv-sQE%7C?hl=en-US">
                <p class="mb-0">
                  409, Shital Varsha Complex, Shivranjini Cross Road,
                  Ahmedabad, Gujarat 380015
                </p>
              </a>

              <!-- <p class="mb-0">www.nichetech.in</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

  <app-footer></app-footer>
  <!-- 
	<script type="text/javascript" src="js/jquery-3.6.0.min.js"></script> 
	<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" ></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js" ></script>
	<script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.js"></script>
	<script type="text/javascript" src="js/main.js"></script> -->
</body>

</html>