import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-industry-list',
  templateUrl: './industry-list.component.html',
  styleUrls: ['./industry-list.component.css'],
})
export class IndustryListComponent {
  logos: any[] = [];
  currentIndex: number = 0;
  displayedLogos: string[] = [];
  industryList: any[] = [];
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private route: Router
  ) {}
  ngOnInit() {
    window.scrollTo(0, 0);
    CommonService.ActiveClass('nav-industry');


    this.http
      .post(CommonService.apiUrl + '/get-feedback', {
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          this.industryList = response['data'];
        }
      });
    setTimeout(() => {
      $('.overlay').hide();
    }, 500);
  }
  onHomePageScroll() {
    localStorage.setItem("reqDemo","true");
    this.route.navigateByUrl('/home'); 
  }


  
}
