<header class="header">
    <nav class="navbar sos-nav navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <a class="navbar-brand navbar-main-new mobile-header" href="#"
          ><img src="../../assets/img/logo.png" />
          <p class="mb-0">Field Service Management App for onsite staff
          </p></a
        >
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          (click)="onToogle()"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item nav-home " (click)="onHomeClick()" >
              <a class="nav-link" aria-current="page" >Home</a>
            </li>
            <li class="nav-item nav-services " (click)="onservicesClick()">
              <a class="nav-link" >Highlights</a>
            </li>
            <li class="nav-item nav-feature " (click)="onfeatureClick()">
              <a class="nav-link" >Features</a>
            </li>
            <li class="nav-item nav-industry" (click)="onClickIndustry()">
              <a class="nav-link" >Testimonials</a>
            </li>
            <li class="nav-item nav-blog" (click)="onClickBlog()">
              <a class="nav-link" >Blogs</a>
            </li>
        
            <li class="nav-item nav-pricing" (click)="onClickPricing()">
              <a class="nav-link" >Pricing</a>
            </li>
   
            <li class="nav-item nav-contact" (click)="onContactUs()">
              <a class="nav-link" >Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>