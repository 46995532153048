import { Component } from '@angular/core';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  currentYear: any;

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private route: Router
  ) {}
  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }
  onHome() {
    // Check if already on the home route
    if (this.route.url === '/home') {
      // Navigate away to trigger reload and come back
      this.route.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
        this.route.navigate(['/home']);
      });
    } else {
      this.route.navigate(['/home']);
    }
  }
  onfeature(){
  this.route.navigateByUrl('/features');
  }
  onIndustry(){
  this.route.navigateByUrl('/testimonials');
  }
  onPricing(){
  this.route.navigateByUrl('/pricing');
  }
  onContact(){
  this.route.navigateByUrl('/contact-us');
  }
  onServices(){
  this.route.navigateByUrl('/highlights');
  }
  onBlogs(){
  this.route.navigateByUrl('/blogs');
  }
  
}
