<footer class="footer pt-4">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 button-menu-main mb-5">
        <div class="bottom-menu-new m-auto d-flex w-75 justify-content-between">
          <a (click)="onHome()" class="link">Home</a>
          <a (click)="onServices()" class="link">Highlights</a>
          <a (click)="onfeature()" class="link">Features</a>
          <a (click)="onIndustry()" class="link">Testimonials</a>
          <a (click)="onBlogs()" class="link">Blogs</a>
          <a (click)="onPricing()" class="link">Pricing</a>

          <a (click)="onContact()" class="link">Contact Us</a>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="address-div" (click)="onHome()">
          <img src="../../assets/img/white logo new.png" class="bottom-logo-main">
        </div>
      </div>
      
      <div class="col-lg-6 col-md-6">
        <div class="d-flex align-items-center justify-content-end wp-bottom-main">
          <a  class="a_tag" href="https://api.whatsapp.com/send?phone=+919512180005&text=Hello%20SmartOnSite" target="_blank" style="text-decoration: none;">
          <img src="../../assets/img/whatsapp icon.svg"  width="100">
          
            <div class="num-div">
             

              <p class="m-0 text-light">Contact us now on <b>whatsapp</b></p>
              <h3 class="text-light m-0">+91 95121 80005</h3>
             
            </div>
          </a>
        </div>
   
      </div>
    </div>


    </div>


  <div class="footer-bottom-area">
      <div class="container">
        <div class="copyright ">
          <p>© {{ currentYear }} Smart on Site</p>
         
        </div>
         <div class="poweredby">
            Powered By<a href="http://nichetech.in/" target="_blank"> Nichetech</a>
         </div>
      </div>
    </div>
</footer>