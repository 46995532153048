import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'smart-on-site-front';
  baseUrl = 'Field Service Management Software and App : SmartOnSite'; // This is the base title

  constructor(private titleService: Title, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          // Traverse to the deepest child route
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'), // Only consider the primary outlet
        map(route => {
          const blogTitle = route.snapshot.paramMap.get('blogTitle');
          const featureTitle = route.snapshot.paramMap.get('featureTitle');
          return { blogTitle, featureTitle, routePath: route.routeConfig?.path }; // Get the route path
        })
      )
      .subscribe(({ blogTitle, featureTitle, routePath }) => {
        if (blogTitle) {
          // Update title for blog route
          const formattedBlogTitle = this.capitalize(blogTitle.replace(/-/g, ' '));
          this.setTitle(formattedBlogTitle);
        } else if (featureTitle) {
          // Update title for feature route
          const dynamicPart = this.capitalize(featureTitle.replace(/-/g, ' ')) + ' App';
          this.setTitle(dynamicPart);
        } else if (routePath === 'highlights') {
          this.setTitle('Functionalities of Field Service Management App Highlights');
        } else if (routePath === 'features') {
          this.setTitle('Features of Field Service Management App SmartOnSite');
        } else if (routePath === 'pricing') {
          this.setTitle('Pricing of Field Service Management App SmartOnSite');
        } else if (routePath === 'testimonials') {
          this.setTitle('Testimonials of Field Service Management App SmartOnSite');
        } else {
          this.setTitle(''); // Reset to base title if not on a specified route
        }
      });
  }

  // Helper function to set the page title dynamically
  setTitle(dynamicTitle: string): void {
    if (dynamicTitle) {
      this.titleService.setTitle(`${dynamicTitle}`);
    } else {
      this.titleService.setTitle(this.baseUrl);
    }
  }

  // Capitalize the first letter of each word for better readability in the title
  capitalize(word: string): string {
    return word.replace(/\b\w/g, (char) => char.toUpperCase());
  }
}
