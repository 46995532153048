import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css']
})
export class BlogListComponent {

  logos:any[]=[];
  currentIndex: number = 0;
  displayedLogos: string[] = [];
  blogList: any[] = [];
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private route: Router
  ) {}
  ngOnInit(){
  window.scrollTo(0,0);
  CommonService.ActiveClass('nav-blog');


  this.http
  .post(CommonService.apiUrl + '/get-blog', {
    oauth_key:CommonService.oauth_key
  })
  .subscribe((response: any) => {
    if (response['is_successfull'] === '1') {
      this.blogList = response['data'];

    }
  });
  setTimeout(() => {
    $('.overlay').hide();
  }, 1000);
}
onHomePageScroll(){
  localStorage.setItem("reqDemo","true");
  this.route.navigateByUrl('/home');
}


onClickBlog(blog_id:any,blogTitle:any){
  
  const formattedTitle = blogTitle.replace(/\s+/g, '-').toLowerCase();
  // Navigate to the dynamic route
  localStorage.setItem('blog_title',formattedTitle);

    this.route.navigate(['/blog/' + formattedTitle]);
}
}
