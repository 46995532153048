import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphabeticOnly]'
})
export class AlphabeticOnlyDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: any): void {
    const initialValue = this.el.nativeElement.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (!regex.test(initialValue)) {
      this.el.nativeElement.value = initialValue.replace(/[^a-zA-Z\s]*/g, '');
    }
  }
}
