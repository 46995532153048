import {
  Component,
  OnInit,
  Renderer2,
  ElementRef,
  Input,
  HostListener,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../common.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import * as $ from 'jquery';
import { filter } from 'rxjs/operators';
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('slideAnimation', [
      transition('void => *', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.1s ease-in-out'),
      ]),
      transition('* => void', [
        animate('0.1s ease-in-out', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})

export class HomeComponent implements AfterViewInit {
  private routeSubscription: any;
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private route: Router,
    private renderer: Renderer2,
    private el: ElementRef,
    private fb: FormBuilder
  ) {}
  @ViewChild('loadMoreAnchor', { static: false }) loadMoreAnchor!: ElementRef;
  totalSlides: any;
  loadMore: boolean = true;
  FeatureLoadMore: boolean = true;
  start_services: any = 0;
  start_features: any = 0;
  length_services: any =3;
  length_features: any = 3;
  draw_services: any = 1;
  draw_features: any = 1;
  company_name: string = '';
  email_address: string = '';
  phone_number: string = '';
  company_address: string = '';
  logos: any[] = [];
  currentIndex: number = 0;
  displayedLogos: string[] = [];
  feedBackList: any[] = [];
  servicesList: any[] = [];
  blogList: any[] = [];
  companyImagesList: any[] = [];
  featureList: any[] = [];
  country_code_list: any[] = [];
  currentIndexs = 0;
  country_code_id: any;
  animationDuration: any; // Update the type to string
  company_flag: boolean = false;
  address_flag: boolean = false;
  phone_flag: boolean = false;
  email_flag: boolean = false;
  company_error:any;
  phone_error:any;
  email_error:any;
  address_error:any;
  autoSlideInterval: any;
  heading_modal:any='Request For Demo';
  @ViewChild('infiniteScrollTrigger', { static: false }) infiniteScrollTrigger!: ElementRef;
  @Input() appMaxLength: number = 10;
  @HostListener('input', ['$event']) onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue: string = input.value;
    // Check if the input has the 'appMaxLength' attribute
    if (input.hasAttribute('appMaxLength')) {
      const value = input.value;

      if (value.length > this.appMaxLength) {
        // Update the FormControl value using patchValue
        this.phone_number = value.slice(0,this.appMaxLength);
      }
    }
    if (inputValue && inputValue.startsWith(' ')) {
      input.value = inputValue.trimStart();
    }
  }
  clearForm() {
    this.company_name = '';
    this.email_address = '';
    this.phone_number = '';
    this.company_address = '';
    // this.country_code_id = null; // or a default value

    // Reset validation flags
    this.company_flag = false;
    this.email_flag = false;
    this.phone_flag = false;
    this.address_flag = false;
  }
  // get companyName() {
  //   return this.myForm.get('company_name');
  // }
  // get email() {
  //   return this.myForm.get('email_address');
  // }
  // get phoneNumber() {
  //   return this.myForm.get('phone_number');
  // }
  // get address() {
  //   return this.myForm.get('company_address');
  // }

  onHomePageScroll() {
    window.scrollTo(0, 0);
  }
  onKeyDown(event: KeyboardEvent): void {
    // Prevent the default behavior of the up and down arrow keys
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  }

  onKeyUp(event: KeyboardEvent): void {
    // Prevent the default behavior of the up and down arrow keys
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  }
  ngAfterViewInit() {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && this.loadMore) {
        this.onClickLoadMore(); // Trigger the load more function when in view
      }
    });

    observer.observe(this.infiniteScrollTrigger.nativeElement);

    const observerFeature = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.onFeatureLoadMore();
        }
      });
    });
    
    observerFeature.observe(this.loadMoreAnchor.nativeElement);
  }
  ngOnInit() {
    CommonService.ActiveClass('nav-home');
    window.scrollTo(0, 0);

    $('.alert-danger').hide();
    $('.alert-success').hide();

  const reqDemo = localStorage.getItem('reqDemo');
  if (reqDemo === 'true') {
    // Open the modal
    const modalElement = document.getElementById('staticBackdrop');
    if (modalElement) {
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }
    // Clear the flag
    localStorage.removeItem('reqDemo');
  }
    this.http
      .post(CommonService.apiUrl + '/get-feedback', {
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          this.feedBackList = response['data'];
          this.totalSlides = this.feedBackList.length;
          this.calculateAnimationDuration();
        }
      });
    this.http
      .post(CommonService.apiUrl + '/country-code', {
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          this.country_code_list = response['data'];
          if (this.country_code_list && this.country_code_list.length > 0) {
            this.country_code_id = this.country_code_list[0].country_code_id;
          }
        }
      });
    this.http
      .post(CommonService.apiUrl + '/get-features', {
        start: this.start_features,
        length: this.length_features,
        draw: this.draw_features,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          this.featureList = response['data'];
          let totalRecords = response['recordsTotal'];
          let filterRecords = this.featureList.length;
          let diff = totalRecords - filterRecords;
          if (diff === 0 || diff < 0) {
            this.FeatureLoadMore = false;
          } else {
            this.FeatureLoadMore = true;
          }
        }
      });

    this.http
      .post(CommonService.apiUrl + '/blog-list', {
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          this.blogList = response['data'];
        }
      });

    this.http
      .post(CommonService.apiUrl + '/get-services', {
        start: this.start_services,
        length: this.length_services,
        draw: this.draw_services,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          this.servicesList = response['data'];
          let totalRecords = response['recordsTotal'];
          let filterRecords = this.servicesList.length;
          let diff = totalRecords - filterRecords;
          if (diff === 0 || diff < 0) {
            this.loadMore = false;
          } else {
            this.loadMore = true;
          }
        }
      });
    setTimeout(() => {
      $('.overlay').hide();
    }, 1000);
    // Adjust the interval as needed (e.g., 2000ms for 2 seconds)
  }

  calculateAnimationDuration() {
    if (this.totalSlides > 0) {
      this.animationDuration = this.totalSlides * 10 + 's'; // Adjust the factor as needed
      document.documentElement.style.setProperty(
        '--animation-duration',
        this.animationDuration
      );
      document.documentElement.style.setProperty(
        '--total-slides',
        this.totalSlides.toString()
      );
    }
  }

  submit(form: NgForm) {

   if (this.validation()  === true) {  // Check if validation is successful
        $('.overlay').show();

        let country_code_id = this.country_code_id; // Ensure country_code_id is defined
        this.http
          .post(CommonService.apiUrl + '/submit', {
            company_name: this.company_name,
            email: this.email_address,
            phone_number: this.phone_number,
            address: this.company_address,
            country_code_id: country_code_id,
            oauth_key: CommonService.oauth_key,
          })
          .subscribe((response: any) => {
            if (response['is_successfull'] === '1') {
              this.heading_modal="Form Submitted Successfully"
              $('.alert-success').show();
              $('.overlay').hide();
              $('.form').hide();

              $('.alert-success').html('Thank you for requesting a demo! We will get back to you soon.');
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            } else {
              // Handle other cases if needed
            }
          });
      } else {
        return ;
      }
    
  }

  validation() {
    $('.alert-danger').hide();
    let error_message = '';
    this.address_flag = false;
    this.company_flag = false;
    this.email_flag = false;
    this.phone_flag = false;
    if (
      this.company_name === '' ||
      this.company_name === ' ' ||
      this.company_name === undefined ||
      this.company_name === null
    ) {
      error_message = 'Please enter Company Name.';
      this.company_flag = true;
      this.company_error= 'Please enter Company Name.';
    } else {
      if (this.company_name.length < 2) {
        error_message += 'Please enter minimum 2 characters.';
        this.company_flag = true;
        this.company_error = 'Please enter minimum 2 characters.';
      }
      if (this.company_name.length > 100) {
        error_message += 'Please enter maximum 100 characters.';
        this.company_flag = true;
        this.company_error = 'Please enter maximum 100 characters.';
      }
    }
    if (this.email_address === ''|| this.email_address === ' '|| this.email_address === undefined || this.email_address === null) {
      error_message += 'Please enter Email.';
      this.email_flag = true;
      this.email_error = 'Please enter Email.';
    } else {
      // Check for proper email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.email_address)) {
        error_message += 'Please enter a valid Email.';
        this.email_flag = true;
        this.email_error = 'Please enter a valid Email.';
      }
    }
    if (
      this.phone_number === '' ||
      this.phone_number === ' ' ||
      this.phone_number === undefined ||
      this.phone_number === null
    ) {
      error_message += 'Please enter Mobile Number.';
      this.phone_flag = true;
      this.phone_error = 'Please enter Mobile Number.';
    } else {
      // Check for proper phone number format
      const phoneRegex = /^\d{10}$/; // Adjust the regex based on your specific phone number format
      if (!phoneRegex.test(this.phone_number)) {
        error_message += 'Please enter a valid Mobile Number.';
        this.phone_flag=true;
        this.phone_error = 'Please enter a valid Mobile Number.';
      }
    }
    if (
      this.company_address === '' ||
      this.company_address === ' ' ||
      this.company_address === undefined ||
      this.company_address === null
    ) {
      error_message += 'Please enter Address.';
      this.address_flag = true;
      this.address_error = 'Please enter Address.';
    } else {
      // I want logic for the minimum and maximum
      if (this.company_address.length < 2) {
        error_message += 'Please enter minimum 2 characters.';
        this.address_flag = true;
        this.address_error = 'Please enter minimum 2 characters.';
      }
      if (this.company_address.length > 300) {
        error_message += 'Please enter maximum 300 characters.';
        this.address_flag = true;
        this.address_error = 'Please enter maximum 300 characters.';
      }
    }

    if (error_message !== '') {

      return false;
    } else {
      return true;
    }
  }

 
  

  onClickLoadMore() {
    this.start_services = this.start_services + 3;
    this.draw_services = this.draw_services + 1;
    this.http
      .post(CommonService.apiUrl + '/get-services', {
        start: this.start_services,
        length: this.length_services,
        draw: this.draw_services,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          for (let i = 0; i < response['data'].length; i++) {
            this.servicesList.push(response['data'][i]);
          }

          const totalRecords = response['recordsTotal'];
          const filterRecords = this.servicesList.length;
          const diff = totalRecords - filterRecords;

          if (diff <= 0) {
            this.loadMore = false;
          } else {
            this.loadMore = true;
          }
        }
      });
  }
  onFeatureLoadMore() {
    this.start_features = this.start_features + 3;
    this.draw_features = this.draw_features + 1;
    this.http
      .post(CommonService.apiUrl + '/get-features', {
        start: this.start_features,
        length: this.length_features,
        draw: this.draw_features,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          for (let i = 0; i < response['data'].length; i++) {
            this.featureList.push(response['data'][i]);
          }
          let totalRecords = response['recordsTotal'];
          let filterRecords = this.featureList.length;
          let diff = totalRecords - filterRecords;
          if (diff === 0 || diff < 0) {
            this.FeatureLoadMore = false;
          } else {
            this.FeatureLoadMore = true;
          }
        }
      });
  }
  serviceReadMore() {
    this.route.navigateByUrl('/highlights');
  }
  // onFeatureReadMore() {
  //   this.route.navigateByUrl('/features');
  // }
  onFeatureReadMore(feature_id:any,featureTitle:any){
    const formattedTitle = featureTitle.replace(/\s+/g, '-').toLowerCase();
  
    // Navigate to the dynamic route
    localStorage.setItem('feature_id', feature_id);
      this.route.navigate(['/' + formattedTitle]);
  }
  onClickBlog() {
    this.route.navigateByUrl('/blogs');
  }
  onClickTestinomial() {
    this.route.navigateByUrl('/testimonials');
  }
  validateCompanyName() {
    this.company_flag = false;
    
    // Remove leading/trailing spaces
    const trimmedCompanyName = this.company_name ? this.company_name.trim() : '';
  
    if (!trimmedCompanyName || trimmedCompanyName.length === 0) {
      this.company_flag = true;
      this.company_error = 'Please enter Company Name.';
    } else if (trimmedCompanyName.length < 2) {
      this.company_flag = true;
      this.company_error = 'Please enter minimum 2 characters.';
    } else if (trimmedCompanyName.length > 100) {
      this.company_flag = true;
      this.company_error = 'Please enter maximum 100 characters.';
    } else {
      // Regex to allow letters, numbers, &, ., -, ,, and spaces, disallowing special symbols like @, #, %, *
      const companyNameRegex = /^[a-zA-Z0-9&.,\-\s]+$/;
      
      if (!companyNameRegex.test(trimmedCompanyName)) {
        this.company_flag = true;
        this.company_error = 'Invalid characters found. Allowed: Letters, numbers, &, ., -, ,, and spaces.';
      } else {
        this.company_flag = false;  // Validation passed
        this.company_error = '';
      }
    }
  }
  
  
  validateEmail() {
    this.email_flag = false;
    if (!this.email_address || this.email_address.trim().length === 0) {
      this.email_flag = true;
      this.email_error = 'Please enter Email.';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.email_address)) {
        this.email_flag = true;
        this.email_error = 'Please enter a valid Email.';
      }
    }
  }
  
  validatePhoneNumber(event: Event) {
    this.phone_flag = false;
    this.phone_error = '';
  
    // Get the input element's value
    const input = event.target as HTMLInputElement;
    
    // Remove all non-numeric characters (including dashes)
    let sanitizedInput = input.value.replace(/\D/g, '');
  
    // Set the phone_number variable with the sanitized input
    this.phone_number = sanitizedInput;
  
    // Set the input value in the HTML element to the sanitized input
    input.value = sanitizedInput;
  
    // Validation: Check if the input is empty or not exactly 10 digits
    if (!this.phone_number || this.phone_number.length !== 10) {
      this.phone_flag = true;
      this.phone_error = 'Please enter a valid Mobile Number (10 digits, no dashes or spaces).';
    }
  }
 
  validateAddress() {
    this.address_flag = false;
    if (!this.company_address || this.company_address.trim().length === 0) {
      this.address_flag = true;
      this.address_error = 'Please enter Address.';
    } else if (this.company_address.length < 2) {
      this.address_flag = true;
      this.address_error = 'Please enter minimum 2 characters.';
    } else if (this.company_address.length > 300) {
      this.address_flag = true;
      this.address_error = 'Please enter maximum 300 characters.';
    }
  }
  
}
